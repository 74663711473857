<template>
	<div class="file-upload-wrap">
		<div class="fl">
			<input type="text" readonly class="input" :value="fileName" />
			<button v-if="file != null" type="button" class="button-close" @click="resetFileUpload"><i class="icon-close"></i><span class="sr-only">삭제</span></button>
		</div>
		<div class="fr">
			<label class="button-default is-small is-outlined is-rounded"
				>찾기
				<input ref="fileUpload" type="file" @change="handleFileChange" />
			</label>
		</div>
	</div>
</template>
<script>
export default {
	name: 'FileUpload',
	props: {},
	components: {},
	computed: {},
	data: () => ({
		file: null,
		fileName: '파일을 선택하세요.',
	}),
	methods: {
		handleFileChange(e) {
			this.file = e.target.files[0];
			this.fileName = e.target.files[0].name;
		},
		resetFileUpload(e) {
			this.fileName = '파일을 선택하세요.';
			this.$refs.fileUpload.value = null;
			this.file = null;
		},
	},
};
</script>
