<template>
	<main class="page-container" id="reservation-inquiry">
		<reserv-page-header title="예약문의" />

		<div class="page-component">
			<div class="box-gray mb24">
				<strong>공간이용 문의</strong>
				<div class="flex align-items-center justify-content-between">
					<div class="fl">
						<span> 문의 사항을 남겨주세요. </span>
					</div>
					<div class="fr">
						<router-link :to="'/space/inquiry/List'" class="button-default is-rounded is-outlined">내 문의 내역</router-link>
					</div>
				</div>
			</div>
			<form action="">
				<div class="reserv-forms">
					<!-- s: 예약자 정보 -->
					<fieldset class="fieldset-section">
						<div class="fieldset-content">
							<div class="field field-row mobile-flex">
								<div class="field-label w160"><label for="">아이디</label></div>
								<div class="control field-form">
									<span class="input no-border">{{ session.userNm }}</span>
								</div>
							</div>
							<div class="field field-row">
								<div class="field-label w160"><label for="">공간명</label><em class="required">필수</em></div>
								<div class="control field-form">
									<select class="form-select" v-model="inptForm.spaceId">
										<option value="0">선택</option>
										<option v-for="(option, idx) in localMapList" :value="option.spaceId" :key="idx">{{ option.spaceNm }}</option>
									</select>
								</div>
							</div>
							<div class="field field-row">
								<div class="field-label w160"><label for="">연락처</label><em class="required">필수</em></div>
								<div class="control field-form">
									<input v-model="cnctelNo" type="tel" class="input" />
								</div>
							</div>
							<div class="field field-row">
								<div class="field-label w160"><label for="">이메일</label></div>
								<div class="control field-form">
									<input v-model="email" type="text" class="input" />
								</div>
							</div>
							<div class="field field-row">
								<div class="field-label w160"><label for="">제목</label><em class="required">필수</em></div>
								<div class="control field-form">
									<input v-model="inptForm.inqTit" type="text" class="input" />
								</div>
							</div>
							<div class="field field-row align-items-start">
								<div class="field-label w160"><label for="">문의내용</label><em class="required">필수</em></div>
								<div class="control field-form">
									<textarea v-model="inptForm.inqCn" class="input textarea" rows="13" placeholder="상담 내용을 입력하세요."></textarea>
								</div>
							</div>
							<div class="field field-row">
								<div class="field-label w160"><label for="">첨부파일</label></div>
								<div class="control field-form">
									<div class="file-upload-wrap">
										<div class="fl">
											<input type="text" readonly class="input" :value="inptForm.inqFileNm" />
											<button v-if="inptForm.inqFileUrl" type="button" class="button-close" @click="resetFileUpload"><i class="icon-close"></i><span class="sr-only">삭제</span></button>
										</div>
										<div class="fr">
											<label class="button-default is-small is-outlined is-rounded"
												>찾기
												<input ref="rprs" type="file" accept="image/jpeg, image/png" @change="setImageInfo" />
											</label>
										</div>
									</div>
								</div>
							</div>
						</div>
					</fieldset>

					<div class="buttons flex justify-content-center">
						<!-- <span class="gachi-sm-loader" v-if="isLoading"></span> -->
						<button @click.prevent="onClickSave" type="button" class="button-default is-large is-secondary is-rounded">등록</button>
						<!-- <loading-button
							:button-text="'등록'"
							:is-loading="isLoading"
							:click-func="onClickSave"
							:button-classes="['button-default', 'is-large', 'is-secondary', 'is-rounded']"
							:style-obj="{'margin-right': '5px'}"
							:text-classes="[]"
							loading-size="sm"
						></loading-button> -->
						<loading-modal v-if="isLoading"></loading-modal>
					</div>
				</div>
			</form>
		</div>
	</main>
</template>

<script>
import LoadingModal from '../../../components/common/LoadingModal.vue';
import LoadingButton from '../../../components/common/LoadingButton.vue';
import { mapGetters } from 'vuex';
import ReservPageHeader from '@/components/space/PageHeader';
import FileUpload from '@/components/common/FileUpload';
import { ACT_GET_SPACE_LIST, ACT_INSERT_SPACE_INQ, ACT_UPLOAD_COMMON } from '@/store/_act_consts';
import { MUT_SHOW_ALERT, MUT_SHOW_CONFIRM, MUT_SET_RETURN_ROUTE } from '@/store/_mut_consts';
import { getCheckItems, isSuccess, phoneNumberMask } from '@/assets/js/utils';
import { getTokenByKey } from '@/assets/js/jwt.service';
export default {
	name: 'SpaceRsvtInquiryInput',
	components: {
		ReservPageHeader,
		FileUpload,
		LoadingButton,
		LoadingModal,
	},
	data: () => ({
		localMapList: [],
		inptForm: {
			spaceId: 0,
			pgsStsDcd: '1412001',
			inqTit: '',
			inqCn: '',
			inqFileUrl: '',
			inqFileNm: '파일을 선택하세요.',
			cnctelNo: '',
			email: '',
		},
		cnctelNo: '',
		email: '',
		rprsReader: null,
		isLoading: false,
	}),
	computed: {
		...mapGetters('auth', ['session', 'isAuth']),
	},
	watch: {
		session: {
			handler(newVal, oldVal) {
				this.cnctelNo = newVal.mblNo;
				this.email = newVal.userEmail;
			},
			immediate: true,
		},
		cnctelNo() {
			this.cnctelNo = phoneNumberMask(this.cnctelNo);
		},
	},
	created() {
		this.inptForm.spaceId = this.$route.params.spaceId;
		this.setImageReader();
		this.isLoginCheck();
		this.getSpaceList();
	},
	methods: {
		setImageReader() {
			this.rprsReader = new FileReader();
			this.rprsReader.onload = (event) => {
				this.inptForm.inqFileUrl = event.target.result;
			};
		},
		setImageInfo() {
			if (this.$refs.rprs.files && this.$refs.rprs.files.length > 0) {
				const file = this.$refs.rprs.files[0];
				const isImg = file.type === 'image/png' || file.type === 'image/jpeg';

				if (!isImg) {
					this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
						title: '업로드 파일은 jpeg/png, image/jpeg 형식만 가능합니다.',
						html: true,
					});
					return;
				}

				this.inptForm.inqFileNm = file.name;
				this.rprsReader.readAsDataURL(file);
			}
		},
		resetFileUpload(e) {
			this.inptForm.inqFileNm = '파일을 선택하세요.';
			this.inptForm.inqFileUrl = '';
		},
		isLoginCheck() {
			if (!getTokenByKey('auth_token')) {
				this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
					title: '회원가입 및 로그인이 필요합니다.',
					html: true,
					yesfunc: () => {
						this.$store.commit(`common/${MUT_SET_RETURN_ROUTE}`, this.$route.path);
						this.$router.push({ name: 'Login' });
					},
				});
				return false;
			}
		},
		getSpaceList() {
			this.$store.dispatch(`space/${ACT_GET_SPACE_LIST}`, {}).then((res) => {
				this.localMapList = getCheckItems(res);
			});
		},
		async saveInq() {
			if (this.$refs.rprs && this.$refs.rprs.files && this.$refs.rprs.files.length > 0) {
				const imgRes = await this.$store.dispatch(`common/${ACT_UPLOAD_COMMON}`, { menuType: 'space_inq', contentsType: 'img', fileList: this.$refs.rprs.files });
				if (imgRes && imgRes.data && imgRes.data.result && imgRes.data.result.number === 200) {
					this.inptForm.inqFileUrl = imgRes.data.filePath;
				}
			}
			const payload = {
				...this.inptForm,
				cnctelNo: this.cnctelNo.replace(/-/gi, ''),
				email: this.email,
			};
			this.$store.dispatch(`space/${ACT_INSERT_SPACE_INQ}`, payload).then((res) => {
				if (isSuccess(res)) {
					this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
						title: `문의내역이 등록되었습니다.`,
						yesfunc: () => {
							this.$router.push({ name: 'SpaceRsvtInquiryList' });
						},
						rightNow: true,
					});
				} else {
					this.$store.commit(`common/${MUT_SHOW_ALERT}`, { title: '오류가 발생했습니다. 다시 시도해주세요.' });
				}
			});
		},
		onClickSave() {
			if (this.validateEsntl()) {
				if (this.isLoading) return;
				this.isLoading = true;
				this.$store.commit(`common/${MUT_SHOW_CONFIRM}`, {
					title: '등록하시겠습니까?',
					showCancelButton: true,
					yesfunc: this.saveInq,
					nofunc: () => {
						this.isLoading = false;
					},
				});
			}
		},
		validateEsntl() {
			let msg = '';
			let result = false;
			if (!Number(this.inptForm.spaceId)) {
				msg = '공간을 선택해주세요.';
			} else if (!this.cnctelNo.trim()) {
				msg = '연락처를 입력해주세요.';
			} else if (!this.inptForm.inqTit.trim()) {
				msg = '제목을 입력해주세요.';
			} else if (!this.inptForm.inqCn.trim()) {
				msg = '문의내용을 입력해주세요.';
			} else {
				result = true;
			}
			if (!result) {
				const param = { title: msg };
				this.$store.commit(`common/${MUT_SHOW_ALERT}`, param);
			}

			return result;
		},
	},
};
</script>
